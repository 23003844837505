.login-container .logo {
  display: flex;
  align-items: center;
}

.login-container .logo img {
  height: 80px;
}


.left-container {
  background-color: #1a8bf7;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-family: Isidora;
}

.left-container h5 {
  text-align: center;
  padding-left: 120px;
  padding-right: 120px;
}

.left-container h2 {
  font-size: 42px;
  color: #fff;
  font-family: Isidora;
  margin-bottom: 10px;
}

.left-container p {
  font-size: 22px;
  color: #fff;
  font-family: Isidora;
  margin-top: 0;
  margin-bottom: 10px;
}

.right-container .login-logo {
  display: none;
}



@media only screen and (max-width: 768px) {
  .right-container .login-logo {
    display: block;
  }

  .left-container {
    display: none;
  }

  .storeBtn{
    flex-direction: column;
   
  }

 


}

/* .checkbox-container {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}





.checkbox-custom {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: #eee;
  border-radius: 3px;
  position: relative;
  vertical-align: middle;
  margin-right: 10px;
} */


/* Style the text next to the checkbox */
/* .checkbox-label {
  vertical-align: middle;
} */