.boxone,.boxtwo{
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.document-card{
    height: auto;
    padding: 10px;
}
.document-card .heading{
    display: flex;
    justify-content: space-between;
}
.underline{
    border-bottom: 3px solid #3a75ce;
    border-radius: 10px;
    margin: 10px 0;
}
.doc-content{
    text-align: center;
    margin: 20px;
}
.doc-content p{
    color: darkgrey;
}
.content-icon{
opacity: 0.5;
color: darkgrey;
height: 150px;
width: 150px;
}
.document-card a{
    text-decoration: none;
    color: #3a75ce;
    font-weight: 600;
}