@import url('../../Pages/Styles/Common/dash.css');
/* .content {
    overflow-y: auto;
    height: 83vh;
      padding: 20px;
      margin-top: 10px;
    } */
    /* .content::-webkit-scrollbar {
      width: 2px;
      display: none;
    } */
    
    
    /* .content::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    
    
    .content::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 4px;
    }
    
    
    .content::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
     */
    /* .content-list {
      list-style: none;
      padding: 0;
      margin: 0;
    } */
    
    /* .side-link {
      margin-bottom: 15px;
    } */
    
    /* .side-link a {
      display: flex;
      align-items: center;
      text-decoration: none;
     
      color: var(--sidebar-text-color)
    }
    
    .new-icon {
      width: 20px; 
      height: 20px;
      margin-right: 10px;
    }
    
    .nav-text {
      font-size: 16px;
    }
    .side-link {
      margin-bottom: 15px;
      border-bottom: 1px solid #ccc; 
      padding-bottom: 15px; 
    }
    
    .side-link:last-child {
      border-bottom: none; 
    }
   */
   
   
    /* .right-sidebar {
      position: fixed;
      top: 0;
      right: 0;
      width: 40%; 
      height: 100vh;
      background-color: #ffffff;
      box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.1); 
      z-index: 999; 
    }
    @media screen and (max-width: 768px) {
      .right-sidebar{
        width: 100%;
      }
    } */
  

    
/* CSS for new sidebar */


.header_title {
  display: flex;
  flex: 1;
  margin-bottom: 30px;
  align-items: center;
  margin-left: 20px;
}
.header_title button {
  display: inline-block;
  margin-inline-end: 12px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 600;
  font-size: 20px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  margin-top: 20px;
  transition: color 0.2s;
  text-rendering: auto;
}
.new-title {
  flex: 1;
  margin-top: 20px;
  color: black;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
}
.plus {
  margin-right: 10px;
}
.content {
  flex: 1;
  margin-top: 20px;

  min-width: 0;
  min-height: 0;
  overflow: auto;
}
.new-icon {
  margin-inline-end: 4px;
  color: #000;
  font-size: 14px;
  line-height: 1;
}

.content-list {
  margin-top: 10px;
}
.content li {
  list-style: none;
  margin-top: 10px;
}
.content li a {
  height: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-top: 15px;
  color: #000;
  border-radius: 6px;
  text-decoration: none;
}
.content .new-icon {
  min-width: 60px;
  border-radius: 6px;
}
hr {
  margin-top: 10px;
}


