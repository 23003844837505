


.tableFixHead {
  max-width: auto;
  margin: auto;
  margin-top: 3rem;
  overflow-x: auto;
  

}

table {
  font-family: "Open Sans", sans-serif;
  position: relative;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: auto;
  width: 100%;
  border: none;
  
  
  white-space: nowrap;
  thead tr {
      color: black;
      font-size: 1rem;
      font-weight: 500;
      text-align: left;

      th {
        background-color: #007bff;
        color: #fff;
          padding: 0.75rem 1.5rem;
          vertical-align: middle;
         
          border: 1px solid #ccc;
      }
  }

  tbody {
      tr:nth-child(odd) td {
          background: white;
      }

      tr:nth-child(even) td {
          
          background: #fff;
          color: black;
    
      }
      td {
          
          color: black;
          text-align: left;
          padding: 1.5rem 1.5rem;
          vertical-align: middle;
          font-size: 1.125rem;
          font-weight: normal;
          border: 1px solid #ccc;
        
      }
      
  }

  

tr>th:first-child,tr>td:first-child {
  position: sticky;
  left: 0;
 
}
} 
 






.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin-top: 20px;

 
}

.pagination li {
  display: inline-block;
  margin: 0 5px;
}

.pagination li a {
  padding: 2px 9px;
  cursor: pointer;
  border-radius: 50px;
  text-decoration: none;
  color: var(--pagination-color);
}

.pagination li.active a {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

.account-data-con::-webkit-scrollbar {
  display: none;
}

.close-icon {
  background: none;
  border: none;
  color: #007bff;
  /* Adjust as needed */
  cursor: pointer;
}

.close-icon:hover {
  text-decoration: underline;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul>div {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  /* Adjust as needed */
}

ul>div:last-child {
  border-bottom: none;
}

ul>div span {
  cursor: pointer;
}

ul>div span:hover {
  text-decoration: underline;
}


.search-bar {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  /* Adjust as needed */
}

.search-bar input {
  width: calc(100% - 70px);
  /* Adjust as needed */
  padding: 8px;
  border: 1px solid #ccc;
  /* Adjust as needed */
  border-radius: 4px;
  margin-right: 10px;
}

.dropdown {
  position: absolute;

  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-height: 350px;

  overflow-y: auto;

}

.dropdown-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.dropdown-list>div {
  padding: 10px;
  border-bottom: 2px solid #ccc;
}

.dropdown-list>div:last-child {
  border-bottom: none;
}

.dropdown-list>div span {
  cursor: pointer;
}

.dropdown-list>div span:hover {
  text-decoration: underline;
}