
input[type="number"].no-spinner::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.submit-btn {
  padding: 8px;
  margin-top: 15px;
  border: none;
  border-radius: 10px;
  color: white;
  background: #007bff;
  width: 20%;
}
.input-field {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
}

.contact-container {
  position: fixed;
  top: 0;
  right: -66.66%;
  height: 100%;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  transition: right 0.5s ease-in-out;
  padding: 15px;
  z-index: 100;
}

.contact-open {
  right: 0;
}

.contact-header {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  background: rgb(243, 243, 243);
}

.contact-header .contact_title {
  font-size: 18px;
  font-weight: 600;
  font-family: sans-serif;
  width: 85%;
  float: left;
  cursor: pointer;
}

.contact-header .contactheader-button {
  background: none;
  color: rgb(58, 145, 245);
  border: none;
  width: 15%;
  float: left;
  cursor: pointer;
}



.custom-button {
  background-color: transparent;
  border: 1px solid rgb(58, 145, 245);
  border-radius: 5px;
  padding: 5px 10px;
  color: rgb(58, 145, 245);
  transition: background-color 0.3s, color 0.3s;
}

.custom-button:hover {
  background-color: rgb(58, 145, 245);
  color: white;
}

@media screen and (max-width: 600px) {
  .button-container {
    flex-direction: column;
  }
}

/* phone number */

.contact-create {
  margin: 10px;
}

.contact-item {
  margin-bottom: 10px;
margin-left: 10px;

}

.contact-label {
  display: block;
  margin-bottom: 10px;
}

.phone-input {
  width: 80%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-left: 35px;
  position: relative;
}




.contact-item .remove-button-phone {
  position: absolute;
  right: 30px;
  height: 35px;
  transform: translateY(-100%);
  color: red;
  background: none;
}


/* .remove-button-phone{
  transform: translateY(-100%);
  margin-left: 80px;
  float: left;
  color: "red";
  background: "none";
   position:'absolute'
} */
/* .add-button,
.send-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 12px;
  margin-top: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.add-button:hover,
.send-button:hover{
  background-color: #0056b3;
}

.remove-button:hover {
  background-color: red;
}

.error-message {
  color: red;
  margin-top: 5px;
} */