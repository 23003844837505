.contact-table table{
    /* border: 2px solid forestgreen; */
    width: 100%;
    height: 200px;

}
.contact-table th {
    border-bottom: 1px solid black;
    background: none;
    color: black;
}
 .contact-table{
    margin-top: 20px;
 }
.contact-table td {
    text-align: center;
}