/* MultiStepProgressBar.css */
.progress-bar {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.progress-step {
  width: 30px;
  height: 30px;
  border: 2px solid #3498db;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  background-color: white;
  color: #3498db;
}

.progress-step.completed {
  background-color: #3498db;
  color: white;
  border-color: #3498db;
}

.checkmark {
  font-size: 16px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.line {
  position: absolute;
  height: 2px;
  width: 100%;
  background-color: #3498db;
  top: 50%;
  left: 50%;
  transform-origin: 0% 50%;
  transform: translate(-50%, -50%) scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.progress-step.completed .checkmark {
  opacity: 1;
}

.progress-step.completed + .progress-step .line {
  transform: translate(-50%, -50%) scaleX(1);
}
